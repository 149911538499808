import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  Form,
  Button,
  Alert,
  Header,
  SpaceBetween,
  Container,
  Multiselect,
  StatusIndicator,
  Select,
  SelectProps,
  ColumnLayout,
  FormField,
  Checkbox,
} from "@amzn/awsui-components-react-v3/polaris";

import {brdParsing, createAceRecord, extractSections} from "../../../redux/actions/ace-action";
import {parseS3Link} from "../../../utils/s3-utils";
import {
  AceTechnology,
  BRD_TECHNOLOGY_LIST,
  AceDocRowHeaders, COUNTRY_LIST, CountryCode,
} from "../../../utils/constants";
import UploadFileDropzone from "../testplan-emi/manual-upload-module/file-dropzone";
import {FileStatus} from "../../../model/upload";
import constants from "../../../constants";
import UploadingModal from "../testplan-emi/manual-upload-module/uploading-model";
import {fileUploadS3Call} from "../../upload/manual-upload-module/utils";
import AceJiraAutomation from "./jira-automation";
import AceRadiatedTestingLimits from "./radiated-testing-limits";
import AceRadiatedTestingStandards from "./radiated-testing-standards";
import {Input} from "@amzn/awsui-components-react-v3";

type ValidationState = {
  isValid: boolean;
  aceDocumentNameMessage?: string;
}

function AceCreate() {
  const description = "Upload ACE docs";
  const hasError: boolean = false;
  const dispatch = useDispatch();
  const {
    extractSectionsRequest,
    extractSectionsLoadingStatus,
    extractSectionsResponse,
    brdParsingRequest,
    brdParsingLoadingStatus,
    brdParsingResponse,
    createAceRecordLoadingStatus,
  } = useSelector((state: any) => state.aceReducer);

  const [aceDocumentName, setAceDocumentName] = useState<string>("");
  const [disableAceDocumentName, setDisableAceDocumentName] = useState<boolean>(false);
  const [aceId, setAceId] = useState<string>("");
  const [validationState, setValidationState] = React.useState<ValidationState>({
    isValid: true,
  });

  const [filesUploadStatus, setFilesUploadStatus] = useState<number>(
    constants.LOADING_DEFAULT
  );
  const [combinedBrdParsingState, setCombinedBrdParsingState] = useState<{
    [fileName: string]: any;
  }>({});

  const [uploadedFileOptions, setUploadedFileOptions] =
    useState<SelectProps.Options>([]);
  const [selectedFileMap, setSelectedFileMap] = useState<{
    [header: string]: string | null;
  }>({});
  const [sectionOptionsMap, setSectionOptionsMap] = useState<{
    [fileName: string]: SelectProps.Options;
  }>({});
  const [selectedSectionOptionsMap, setSelectedSectionOptionsMap] = useState<{
    [fileName: string]: SelectProps.Options;
  }>({});

  const [selectedTechnologies, setSelectedTechnologies] =
    useState(BRD_TECHNOLOGY_LIST);
  const selectAllTechnologies =
    selectedTechnologies.length === BRD_TECHNOLOGY_LIST.length;
  const [selectedCountries, setSelectedCountries] = useState(COUNTRY_LIST);
  const selectAllCountries = selectedCountries.length === COUNTRY_LIST.length;

  const [fileData, setFileData] = useState<{
    fileList: Array<File>;
    fileCheckDict: {
      [key: string]: FileStatus;
    };
    fileUploadDict: {
      [key: string]: FileStatus;
    };
  }>({fileList: [], fileCheckDict: {}, fileUploadDict: {}});

  const [showModal, setShowModal] = useState<boolean>(false);
  const {fileList, fileCheckDict, fileUploadDict} = fileData;
  const _deleteFile = (filename: string) => {
    if (filename in fileCheckDict) {
      delete fileCheckDict[filename];
    }

    if (filename in fileUploadDict) {
      delete fileUploadDict[filename];
    }

    const fileListName = fileList.filter((file: File) => {
      return file.name !== filename;
    });
    setFileData({
      fileList: fileListName,
      fileCheckDict: fileCheckDict,
      fileUploadDict: fileUploadDict,
    });
  };

  // event: when on drag and drop file in dropzone
  const _onChangeFiles = (files: Array<File>) => {
    const newFileList: Array<File> = [];
    const newFileCheckDict: { [key: string]: FileStatus } = {};
    const newFileUploadDict: { [key: string]: FileStatus } = {};

    files.forEach((file: File) => {
      // repeated file
      if (!(file.name in fileCheckDict)) {
        newFileList.push(file);
        newFileCheckDict[file.name] = {
          status: constants.LOADING_SUCCESS,
          message: "",
          s3_link: "",
        };
        newFileUploadDict[file.name] = {
          status: constants.LOADING_SUCCESS,
          message: "",
          s3_link: "",
        };
      }
    });

    setFileData({
      fileList: [...fileList, ...newFileList],
      fileCheckDict: {...fileCheckDict, ...newFileCheckDict},
      fileUploadDict: {...fileUploadDict, ...newFileUploadDict},
    });

    // check file name
    //_validateFilename();
  };

  // onClick clear button
  const _onClear = () => {
    setFileData({
      fileList: [],
      fileCheckDict: {},
      fileUploadDict: {},
    });
    setShowModal(false);
  };

  const _closeModal = () => {
    // clear form
    _onClear();
  };

  /* 
    check if need to disable upload button
    case 1: fileCheckList is empty
    case 2: Any file name is checking or failed
  */
  const _checkDisableUploadButton = () => {
    // case 1:
    if (fileList.length === 0) {
      return true;
    }

    // case 2:
    for (let i = 0; i < fileList.length; i++) {
      const filename = fileList[i].name;
      if (
        fileCheckDict[filename] &&
        fileCheckDict[filename].status !== constants.LOADING_SUCCESS
      ) {
        return true;
      }
    }

    // otherwise, do not disable, return false
    return false;
  };

  /* 
    check file name validation in fileCheckList
    return false if any of the file name is invalid
    return true if all file names are valid
  */
  const _validateForm = () => {
    for (let i = 0; i < fileList.length; i++) {
      const filename = fileList[i].name;
      if (
        fileCheckDict[filename] &&
        fileCheckDict[filename].status === constants.LOADING_FAIL
      ) {
        return false;
      }
    }
    return true;
  };
  const _showModal = () => {
    setShowModal(true);
  };

  const _uploadFiles = async () => {
    setFilesUploadStatus(constants.LOADING_LOAD);

    const uploadPromises = fileList.map(async (file) => {
      const rst = await fileUploadS3Call(file, constants.INITIATIVE_ACE);

      setFileData((prev) => ({
        ...prev,
        fileUploadDict: {...prev.fileUploadDict, [file.name]: rst},
      }));

      setUploadedFileOptions((prev) => [
        ...prev,
        {label: file.name, value: file.name},
      ]);
    });

    await Promise.all(uploadPromises);

    setFilesUploadStatus(constants.LOADING_SUCCESS);
  };
  /*
    onClick Upload button
    1. fileList is empty => alert
    2. validate file name failed => alert
    3. all good => confirm
        3.1 no => clear and return
        3.2 yes => open model, start upload process
  */
  const _onClickUpload = () => {
    // 1. fileList is empty
    if (fileList.length === 0) {
      alert("No file was uploaded.");
      return;
    }

    // 2. validate file name failed
    if (!_validateForm()) {
      alert("Invalid file name.");
      return;
    }

    // 3. all good, confirm
    let isConfirmed = window.confirm(
      "Files will be uploaded to LENS. Click OK to confirm. "
    );

    if (!isConfirmed) {
      return;
    }
    _showModal();
    _uploadFiles();
  };

  const handleFileSelect = (header: string, selectedOption: any) => {
    const fileName = selectedOption.detail.selectedOption.value;
    setSelectedFileMap((prev) => ({...prev, [header]: fileName}));

    // Extract section of the file if data is not already fetched
    if (!sectionOptionsMap[fileName]) {
      const {s3_link} = fileData.fileUploadDict[fileName];
      const {bucket_name} = parseS3Link(s3_link);
      dispatch(extractSections({bucket_name, file_name: fileName}));
    }
  };

  // Update sections data whenever new file is selected
  useEffect(() => {
    const fileName = extractSectionsRequest?.file_name;
    const extractedSections =
      extractSectionsResponse?.data?.["Extracted Sections"] || [];
    if (fileName && extractedSections?.length) {
      setSectionOptionsMap((prev) => ({
        ...prev,
        [fileName]: extractedSections.map((section: string) => ({
          label: section,
          value: section,
        })),
      }));
    }
  }, [extractSectionsRequest, extractSectionsResponse]);

  const handleSectionsSelect = (fileName: string, event: any) => {
    setSelectedSectionOptionsMap((prev) => ({
      ...prev,
      [fileName]: event.detail.selectedOptions,
    }));
  };

  const handleTechnologiesChange = (technology: AceTechnology) => {
    setSelectedTechnologies((prevSelected) => {
      if (prevSelected.includes(technology)) {
        return prevSelected.filter((item) => item !== technology);
      } else {
        return [...prevSelected, technology];
      }
    });
  };

  const handleSelectAllTechnologies = () => {
    setSelectedTechnologies(!selectAllTechnologies ? BRD_TECHNOLOGY_LIST : []);
  };

  const handleCountriesChange = (country: CountryCode) => {
    setSelectedCountries((prevSelected) => {
      if (prevSelected.includes(country)) {
        return prevSelected.filter((item) => item !== country);
      } else {
        return [...prevSelected, country];
      }
    });
  };

  const handleSelectAllCountries = () => {
    setSelectedCountries(!selectAllCountries ? COUNTRY_LIST : []);
  };

  const validateInputs = () => {
    let isValid = true;
    const validationState: ValidationState = {
      isValid: true
    };
    if (!aceDocumentName || aceDocumentName.includes(" ")) {
      validationState["aceDocumentNameMessage"] = "Ace document name is invalid. It cannot be empty and cannot" +
        " contain spaces"
      isValid = false;
    }
    setValidationState(validationState);
    return isValid;
  }
  const handleSubmitSections = () => {
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }
    if (createAceRecordLoadingStatus !== constants.LOADING_SUCCESS) {
      const aceId = `ACE_${aceDocumentName}`
      dispatch(createAceRecord(aceId, selectedCountries, selectedTechnologies))
    }
    Object.entries(selectedSectionOptionsMap).forEach(
      ([fileName, selectedSectionOptions]) => {
        if (fileName && selectedSectionOptions?.length) {
          const {s3_link} = fileData.fileUploadDict[fileName];
          const {bucket_name} = parseS3Link(s3_link);

          const brdParsingRequestPayload = {
            doc_type: bucket_name,
            doc_name: fileName,
            section_names: selectedSectionOptions.map(
              (section) => section.value
            ),
            dfc_doc_type: bucket_name,
            dfc_doc_name: `ACE_${aceDocumentName}.docx`,
          };

          dispatch(brdParsing(brdParsingRequestPayload));
        }
      }
    );
  };

  // Combine state of parsed files in a map
  useEffect(() => {
    const fileName = brdParsingRequest?.doc_name;
    if (fileName) {
      setCombinedBrdParsingState((prev) => ({
        ...prev,
        [fileName]: {
          loadingStatus: brdParsingLoadingStatus,
          response: brdParsingResponse,
        },
      }));
      // Freezing document name once a backend call succeeds because it is being used as ace identifier
      // So it should not change
      if (brdParsingLoadingStatus == constants.LOADING_SUCCESS && !disableAceDocumentName) {
        setDisableAceDocumentName(true);
        setAceId(`ACE_${aceDocumentName}`);
      }
    }
  }, [brdParsingRequest, brdParsingLoadingStatus, brdParsingResponse]);

  const loadingSections =
    extractSectionsLoadingStatus === constants.LOADING_LOAD;

  const isSectionsSelected = Object.entries(selectedSectionOptionsMap).some(
    ([fileName, selectedSectionOptions]) =>
      fileName && selectedSectionOptions?.length
  );

  const loadingBrdParse = brdParsingLoadingStatus === constants.LOADING_LOAD;

  const clearValidationState = () => {
    setValidationState({
      isValid: true,
      aceDocumentNameMessage: "",
    })
  }

  return (
    <Container>
      <div>
        <Form header={<Header variant="h1" description={description}></Header>}>
          {hasError && (
            <React.Fragment>
              <Alert header="Errors Detected" type="error">
                Please check you file name, and try again.
              </Alert>
              <br/>
            </React.Fragment>
          )}

          <UploadFileDropzone
            fileList={fileList}
            fileCheckDict={fileCheckDict}
            onChange={_onChangeFiles}
            deleteFile={_deleteFile}
          />
        </Form>
        <br/>

        <SpaceBetween direction="horizontal" size="xs">
          <Button onClick={_onClear}>Clear</Button>
          <Button
            variant="primary"
            onClick={_onClickUpload}
            disabled={_checkDisableUploadButton()}
          >
            Upload
          </Button>
        </SpaceBetween>

        {filesUploadStatus === constants.LOADING_LOAD && (
          <>
            <br/>
            <StatusIndicator type="loading">Uploading files</StatusIndicator>
          </>
        )}
        {(<>
            <br/>
            <Container
              header={
                <Header variant="h2">Document and Section Selection</Header>
              }
            >
              <SpaceBetween size="l">
                {Object.values(AceDocRowHeaders).map((header) => {
                  const selectedFile = selectedFileMap[header] || "";
                  const selectedFileOption = selectedFile
                    ? {label: selectedFile, value: selectedFile}
                    : null;
                  const sectionOptions = sectionOptionsMap[selectedFile] || [];
                  const selectedSectionOptions =
                    selectedSectionOptionsMap[selectedFile] || [];

                  return (
                    <div key={header}>
                      <Header variant="h3">{header}</Header>
                      <ColumnLayout columns={4}>
                        <FormField label="Document">
                          <Select
                            options={uploadedFileOptions}
                            onChange={(e) => handleFileSelect(header, e)}
                            selectedOption={selectedFileOption}
                            placeholder="Choose a file"
                            disabled={loadingSections}
                          />
                        </FormField>
                        {selectedFile && (
                          <FormField label="Sections">
                            {!sectionOptions?.length ? (
                              <StatusIndicator type="loading">
                                loading
                              </StatusIndicator>
                            ) : (
                              <Multiselect
                                options={sectionOptions}
                                onChange={(e) =>
                                  handleSectionsSelect(selectedFile, e)
                                }
                                selectedOptions={selectedSectionOptions}
                                placeholder="Select"
                              />
                            )}
                          </FormField>
                        )}
                      </ColumnLayout>
                    </div>
                  );
                })}

                <Header variant="h2">
                  Please confirm the list of technologies identified from BRD
                </Header>
                <Checkbox
                  checked={selectAllTechnologies}
                  onChange={handleSelectAllTechnologies}
                >
                  Select All
                </Checkbox>
                <SpaceBetween direction="horizontal" size="m">
                  {BRD_TECHNOLOGY_LIST.map((technology) => (
                    <Checkbox
                      key={technology}
                      checked={selectedTechnologies.includes(technology)}
                      onChange={() => handleTechnologiesChange(technology)}
                    >
                      {technology}
                    </Checkbox>
                  ))}
                </SpaceBetween>

                <Header variant="h2">
                  Please confirm the list of countries identified from BRD
                </Header>
                <Checkbox
                  checked={selectAllCountries}
                  onChange={handleSelectAllCountries}
                >
                  Select All
                </Checkbox>
                <SpaceBetween direction="horizontal" size="m">
                  {COUNTRY_LIST.map((country) => (
                    <Checkbox
                      key={country}
                      checked={selectedCountries.includes(country)}
                      onChange={() => handleCountriesChange(country)}
                    >
                      {country}
                    </Checkbox>
                  ))}
                </SpaceBetween>

                <FormField
                  label="ACE Document Name"
                  description="Please enter an unique name for the ACE document"
                  errorText={validationState?.aceDocumentNameMessage}
                >
                  <Input
                    value={aceDocumentName}
                    placeholder="Enter ACE document name"
                    disabled={disableAceDocumentName}
                    onChange={event => {
                      clearValidationState();
                      setAceDocumentName(event.detail.value)
                    }}
                  />
                </FormField>
                <Button
                  variant="primary"
                  onClick={handleSubmitSections}
                  disabled={!isSectionsSelected || loadingBrdParse}
                >
                  Submit
                </Button>
              </SpaceBetween>
              {aceId && <div>
                  <br/>
                  <AceJiraAutomation
                      aceId={aceId}
                  />
                  <br/>
                  <AceRadiatedTestingLimits
                      aceId={aceId}
                      selectedTechnologies={selectedTechnologies}
                  />
                  <br/>
                  <AceRadiatedTestingStandards
                      aceId={aceId}
                      selectedTechnologies={selectedTechnologies}
                  />
              </div>
              }
            </Container>
          </>
        )}

        <br/>
        {Object.entries(combinedBrdParsingState).map(
          ([fileName, {loadingStatus, response}]) => (
            <div key={fileName}>
              {loadingStatus === constants.LOADING_LOAD && (
                <StatusIndicator type="loading">Parsing BRD</StatusIndicator>
              )}
              {loadingStatus === constants.LOADING_SUCCESS && (
                <>
                  <strong>{fileName}&nbsp;</strong>
                  <StatusIndicator type="success">
                    {response?.message}
                  </StatusIndicator>
                  <div>S3 Link: {response?.data?.s3_link}</div>
                </>
              )}
              {loadingStatus === constants.LOADING_FAIL && (
                <>
                  <strong>{fileName}&nbsp;</strong>
                  <StatusIndicator type="error">
                    {response?.message || "Error in Parsing"}
                  </StatusIndicator>
                </>
              )}
            </div>
          )
        )}
      </div>

      {/* Uploading Modal */}
      <UploadingModal
        showModal={showModal}
        onDismiss={_onClear}
        onClose={_closeModal}
        fileList={fileList}
        fileUploadDict={fileUploadDict}
      />
    </Container>
  );
}

export default AceCreate;
